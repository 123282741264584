<template>
    <component :is="form === undefined ? 'div' : 'b-card'">
        <template>
            <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
                <!-- Form -->
                <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
                    <b-row class="text-center">
                        <b-col cols="11">
                            <!-- media -->
                            <b-media no-body>
                                <b-media-body class="mt-75 ml-75">
                                    <b-row class="justify-content-center">
                                        <b-col cols="12" sm="4">
                                            <!-- upload button -->

                                            <image-cropper v-model="fileForm" label="manager image" :url.sync="form.thumbnail" />
                                            <!--/ upload button -->
                                        </b-col>
                                    </b-row>
                                    <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
                                </b-media-body>
                            </b-media>
                            <!--/ media -->
                        </b-col>
                    </b-row>
                    <b-row class="mt-3 justify-content-center">
                        <!-- Name -->
                        <b-col cols="12" sm="6" md="3">
                            <validation-provider #default="validationContext" name="name" rules="required">
                                <b-form-group label="Item Name" label-for="name">
                                    <b-form-input id="name" v-model="form.name" :state="getValidationState(validationContext)" trim />

                                    <b-form-invalid-feedback>
                                        {{ validationContext.errors[0] }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <validation-provider name="type">
                                <b-form-group label="Type" label-for="type">
                                    <v-select v-model="form.type" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="types" :clearable="false" label="type" input-id="type" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="6" md="3">
                            <validation-provider name="price">
                                <b-form-group label="price" label-for="price">
                                    <b-form-input id="name" v-model="form.price" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col v-if="form.type == 'badge'" cols="12" sm="6" md="3">
                            <validation-provider name="badge_name">
                                <b-form-group label="badge_name" label-for="badge_name">
                                    <v-select v-model="form.badge_name" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="badgeOptions" :clearable="false" label="badge_name" input-id="badge_name" />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col v-if="form.type == 'gift'" cols="12" sm="6" md="3">
                            <validation-provider name="sell_price">
                                <b-form-group label="sell_price" label-for="sell_price">
                                    <b-form-input id="name" v-model="form.sell_price" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col cols="12" sm="6" md="6">
                            <validation-provider name="description">
                                <b-form-group label="description" label-for="description">
                                    <b-form-input id="description" v-model="form.description" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col cols="12" sm="2" md="2">
                            <b-form-checkbox v-model="form.has_discount" class="custom-control-success mt-2"> Has discount? </b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row v-if="form.has_discount == true" class="justify-content-center">
                        <b-col cols="12" sm="4" md="4">
                            <validation-provider #default="validationContext" name="discounted_price" rules="required">
                                <b-form-group label="Discounted price" label-for="discounted_price">
                                    <b-form-input id="discounted_price" v-model="form.discounted_price" :state="getValidationState(validationContext)" trim />
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                        <b-col cols="12" sm="4" md="4">
                            <label>Discount until?</label>
                            <b-form-datepicker id="datepicker-placeholder" placeholder="Choose a Time" local="en" class="mx-1" v-model="start_time" />
                        </b-col>
                        <b-col cols="12" sm="4" md="4">
                            <b-form-timepicker class="mt-2" v-model="time" show-seconds locale="en" />
                        </b-col>
                    </b-row>
                    <b-row class="justify-content-center">
                        <b-col cols="12" sm="2" md="2">
                            <b-form-checkbox v-model="form.is_active" value="1" class="custom-control-success mt-2"> Active </b-form-checkbox>
                        </b-col>
                        <b-col cols="12" sm="2" md="2">
                            <b-form-checkbox v-model="form.visible_in_store" value="1" class="custom-control-success mt-2"> Visible in store </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row> </b-row>
                    <b-row>
                        <b-col cols="12">
                            <div class="d-flex mt-2">
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mr-2" type="submit">
                                    {{ stickerId ? "update" : "add" }}
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-danger"> reset </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </template>
    </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BFormGroup, BForm, BRow, BCol, BFormInput, BMedia, BFormFile, BImg, BFormTextarea, BCardText, BButton, BMediaBody, BMediaAside, BFormInvalidFeedback, BFormCheckbox, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { required } from "@validations";
import { ref } from "@vue/composition-api";
import router from "@/router";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { makeid } from "@/utils/math";
import useStoreItemsList from "./useStoreItemsList";
import useFile from "../file/useFile";

const RANDOM_TOKEN = makeid(50);

export default {
    components: {
        BFormDatepicker,
        BFormTimepicker,
        BFormCheckbox,
        BTab,
        BFormInput,
        BFormInvalidFeedback,
        BFormGroup,
        BForm,
        BFormTextarea,
        BFormFile,
        BImg,
        BMedia,
        BTabs,
        BCard,
        BAlert,
        BRow,
        BCol,
        vSelect,
        BButton,
        BCardText,
        BMediaAside,
        BMediaBody,
        // Form Validation
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        Ripple,
    },
    setup() {
        let blankForm = {
            name: "",
            is_public: 0,
            file_batch_id: RANDOM_TOKEN,
            has_discount: false,
        };
        const types = ref(["sticker_pack", "badge", "gift"]);
        const stickerId = ref(null);
        const start_time = ref();
        const time = ref();
        const form = ref(JSON.parse(JSON.stringify(blankForm)));
        const badge_names = ref([""]);
        const badgeOptions = ref(["Verification", "Celebrity", "Fashionista", "Corporate", "VIP"]);
        if (router.currentRoute.params.id) {
            stickerId.value = router.currentRoute.params.id;
        }

        const { storeFile, fileForm } = useFile(RANDOM_TOKEN, "main_image", "store_items");
        const { storeStoreItem, updateStoreItem, showMainStoreItem } = useStoreItemsList();
        // Register module

        const onSubmit = async () => {
            if (form.value.thumbnail) {
                delete form.value.thumbnail;
            }
            if (fileForm.value.file) {
                await storeFile();
            }
            if (stickerId.value) {
                if (form.value.has_discount == true) {
                    form.value.discount_until = `${start_time.value} ${time.value}`;
                }else if(form.value.has_discount == false){
                    delete form.value.discounted_price;
                }
                console.log(form.value);
                updateStoreItem(stickerId.value, form.value).then((res) => {
                    router.push({ name: "apps-store-items-list" });
                });
            } else {
                console.log(form.value);
                if (form.value.has_discount == true) {
                    form.value.discount_until = `${start_time.value} ${time.value}`;
                }else if(form.value.has_discount == false){
                    delete form.value.discounted_price;
                }
                console.log(form.value);
                storeStoreItem(form.value).then((res) => {
                    router.push({ name: "apps-store-items-list" });
                });
            }
        };

        // Reset Validation
        const resetData = () => {
            form.value = JSON.parse(JSON.stringify(blankForm));
        };

        const { refFormObserver, getValidationState, resetForm } = formValidation(resetData);

        // fill Form When edit page
        const showData = async () => {
            const response = await showMainStoreItem(stickerId.value);
            if (response) {
                form.value = { ...blankForm, ...response };
                blankForm = { ...blankForm, ...response };
            } else {
                form.value = undefined;
            }
            // if (response.is_active == 1) {
            //     form.value.is_active = true;
            // }else{
            //     form.value.is_active = false;
            // }

            if (response.has_discount == 1) {
                form.value.has_discount = true;
            } else {
                form.value.has_discount = false;
            }
            start_time.value = response.discount_until.split(" ")[0];
            time.value = response.discount_until.split(" ")[1];
        };

        if (stickerId.value) {
            showData();
        }

        return {
            form,
            fileForm,
            onSubmit,
            refFormObserver,
            getValidationState,
            resetForm,
            required,
            stickerId,
            updateStoreItem,
            types,
            start_time,
            time,
            badge_names,
            badgeOptions,
        };
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
